import React from "react"
import styled from "styled-components"
import { WindowLocation } from "@reach/router"

interface Props {
  location: WindowLocation
  title: string
}

class Layout extends React.Component<Props> {
  render() {
    const { children } = this.props
    return (
      <Wrapper>
        <div>{children}</div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default Layout
